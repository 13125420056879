import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  loadingSubscription: Subscription | undefined;

  constructor(
    private utility: UtilityService
  ) {
  }

  ngOnInit() {
    this.loadingSubscription = this.utility.loadingStatus.subscribe((value:any) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }

}