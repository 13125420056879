import {NgModule} from '@angular/core';
import {APOLLO_NAMED_OPTIONS, NamedOptions} from 'apollo-angular';
import {InMemoryCache,ApolloLink} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
const parkSlot_uri = 'https://0nimb4o0fk.execute-api.eu-central-1.amazonaws.com/dev/parkslot';
const parkArea_uri = 'https://0nimb4o0fk.execute-api.eu-central-1.amazonaws.com/dev/graphql';

export function createApollo(httpLink: HttpLink): NamedOptions {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('ut');
  
    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
  });
  return {
    parkSlot:{
    link:ApolloLink.from([basic, auth, httpLink.create({uri:parkSlot_uri})]),
    cache: new InMemoryCache(),
  },
  parkArea:{
    link:ApolloLink.from([basic, auth, httpLink.create({uri:parkArea_uri})]),
    cache: new InMemoryCache(),
  }
};
}
@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
