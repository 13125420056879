import { UtilityService } from '../services/utility.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private _utility: UtilityService
  ) {
  }

  transform(key: any): any {
    return this._utility.translateData[key];
  }

}
