import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilityService } from '../utility.service';
import { Observable } from 'rxjs';
@Injectable()
export class AuthGuardService implements CanActivate{

  constructor(public auth: AuthService, public router: Router, public jwtHelper: JwtHelperService, private _utility: UtilityService) { }
  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.jwtHelper.isTokenExpired(localStorage.getItem('ut')!)) {
      this.router.navigate(['auth/login']);
      return false;
    }
    return true
  }
}


