import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { throwError } from "rxjs";
import { Router } from '@angular/router';
@Injectable({
  providedIn: "root"
})
export class NetworkService {
  userCanActive: any;
  
  constructor(
    private http: HttpClient,
    private _router:Router,
  ) { }
  getOptions(): any {
    var httpHeaders: any;
    if (this.token != null) {
      httpHeaders = new HttpHeaders()
      .set(
        "Authorization",
        "Bearer " + this.token
      )
    }
    let options = {
      headers: httpHeaders
    };

    return options;
  }

  async get(url: any): Promise<any> {
    if (this.token) {
      var opt = this.getOptions();
      return this.http
        .get<any>(url,opt)
        .toPromise()
        .then(result => {
          return result;
        })
        .catch(error => {
          console.log(error)
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  async getWithOptions(url: any, params: HttpParams): Promise<any> {
      var httpHeaders: any;
      if (this.token) {
        httpHeaders = new HttpHeaders().set(
          "Authorization",
          "Bearer " + this.token
        );
      let params1 = new HttpParams();
      params1 = params;
      var options1: {
        headers?: HttpHeaders;
        observe?: "body";
        params?: HttpParams;
        reportProgress?: boolean;
        withCredentials?: boolean;
      } = {
        headers: httpHeaders,
        params: params1
      };

      return this.http
        .get<any>(url, options1)
        .toPromise()
        .then(res => {
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  async post(url: any, data: any): Promise<any> {
    if (this.token) {
      var opt = this.getOptions();
      return this.http
        .post<any>(url, data, opt)
        .toPromise()
        .then(res => {        
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }
  async put(url: any, data: any): Promise<any> {
    if (this.token) {
      var opt = this.getOptions();
      console.log(opt)
      return this.http
        .put<any>(url, data, opt)
        .toPromise()
        .then(res => {        
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }
  async delete(url: any): Promise<any> {
    if (this.token) {
      var opt = this.getOptions();
      return this.http
        .delete<any>(url,opt)
        .toPromise()
        .then(result => {
          return result;
        })
        .catch(error => {
          console.log(error)
          this.errorAuth(error)
          return throwError(error);
        });
    } 
  }
  errorAuth(e:HttpErrorResponse){
    console.log(e)
    if(e.status==401){
      
    }
    if(e.status==0){
      this._router.navigate(['login']);
    }
  }
  get token() {
      return localStorage.getItem('ut');
  }
  
}

