import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {
  userInfo:any;
  role:any;
  constructor(private _utility:UtilityService) { }

  ngOnInit() {   
    /*this._utility.user.subscribe((_:any)=>{
      this.userInfo=_;
    });*/
  }

}
