import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy,OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit,OnDestroy {
  tabs=[
    {"id":1,"icon":"dashboard","name":"dashboard","route":"dashboard"},
    {"id":2,"icon":"article","name":"logs","route":"park-logs"},
    {"id":3,"icon":"confirmation_number","name":"tickets","route":"ticket"},
    {"id":4,"icon":"settings_remote","name":"devices","route":"park-slots"},
    {"id":5,"icon":"emoji_transportation","name":"parkArea","route":"park-area"},
    {"id":6,"icon":"help","name":"help","route":"support"}
]
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(
    private _utility:UtilityService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _router:Router,
    public jwtHelper: JwtHelperService,
    private _snackBar: MatSnackBar
    ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.getUserDetails()
  }
  settings() {
    
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['auth/login'])
  }
  selectionChange(snav:any){
    if(this.mobileQuery.matches){
      snav.close();
    }
  }
  getUserDetails(){ 
    if(localStorage.getItem('ut')){
      let decoded = this.jwtHelper.decodeToken(localStorage.getItem('ut')!);
      this._utility.currentLang=decoded.language;
      this._utility.currentTheme=decoded.theme;
      if(this.jwtHelper.isTokenExpired(localStorage.getItem('ut')!)){
        this._snackBar.open('Uygulamaya tekrar giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
      //  this._router.navigate(['auth/login']);
      }
    }else{
      this._snackBar.open('Uygulamaya giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
    //  this._router.navigate(['auth/login']);
    }
  }
}
